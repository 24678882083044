<script setup>
    import { globals } from '#nuxt-prepare'

    const store = useStore()
</script>

<template>
    <div class="flex w-full flex-col items-start gap-8 sm:gap-4 lg:gap-6">
        <QuoteBuilderForm class="w-full sm:w-1/3" />

        <QuoteBuilderHeader
            :title="globals.quote_builder.step_1_title"
            :subTitle="globals.quote_builder.step_1_sub_heading"
            :currentStep="1"
            class="container"
        />

        <CarsList :cars="store.availableCars()" />
    </div>
</template>

<style>
    .cars-container {
        @screen sm {
            > div {
                padding-inline: 0;
            }
        }
    }
</style>
