export const prettyRoundDown = (num, precision = 10000) =>
    Math.floor(num / precision) * precision

export const prettyRoundUp = (num, precision = 10000) =>
    Math.ceil(num / precision) * precision

export const prettyPrice = (price, round = false) => {
    const pretty = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    }).format(price)

    if (round) {
        return pretty.substring(0, pretty.length - 3)
    }

    return pretty.replace('.00', '')
}

export const statePostcodeRanges = () => ({
    ACT: [
        { min: 200, max: 299 },
        { min: 2600, max: 2618 },
        { min: 2900, max: 2920 },
    ],
    NSW: [
        { min: 1000, max: 2599 },
        { min: 2619, max: 2899 },
        { min: 2921, max: 2999 },
    ],
    NT: [{ min: 800, max: 999 }],
    QLD: [
        { min: 4000, max: 4999 },
        { min: 9000, max: 9999 },
    ],
    SA: [{ min: 5000, max: 5999 }],
    TAS: [{ min: 7000, max: 7999 }],
    VIC: [
        { min: 3000, max: 3999 },
        { min: 8000, max: 8999 },
    ],
    WA: [{ min: 6000, max: 6999 }],
})
