<script setup>
    import Slider from '@vueform/slider'
    import Multiselect from '@vueform/multiselect'

    const quoteBuilder = await useStatamic().getGlobal('quote_builder')

    const store = useStore()

    const kmsPerYearOptions = {}

    for (
        let i = quoteBuilder.kms_per_year.lower_amount;
        i <= quoteBuilder.kms_per_year.upper_amount;
        i += quoteBuilder.kms_per_year.step
    ) {
        kmsPerYearOptions[i] = `${i} km`
    }

    const carMakes = ['All', ...store.carMakes()]

    const getQuote = () => {
        store.getQuotesForSelectedCar()
        navigateTo('/quote')
    }

    const scrollToCarsList = () => {
        document.querySelector('div[name="content"] header').scrollIntoView({ behavior: 'smooth' })
    }
</script>

<template>
    <form class="bg-white">
        <div class="form-title lg:flex-0 flex-1">
            <h2 class="text-brand1 xl:text-[1.75rem]">Get a quote</h2>

            <p class="lg:text-sm xl:text-xl">We won't ask for your details</p>
        </div>
        <label class="flex-1">
            <h4>Your weekly vehicle budget</h4>

            <div class="mb-4 text-sm font-bold text-mid lg:text-xs xl:text-sm">
                {{ prettyPrice(store.weeklyBudget[0]) }} - {{ prettyPrice(store.weeklyBudget[1]) }}
            </div>

            <Slider
                v-model="store.weeklyBudget"
                :lazy="false"
                :min="prettyRoundDown(store.lowestCarPrice() / 260, 100)"
                :max="prettyRoundUp(store.highestCarPrice() / 260, 100)"
                :step="100"
                :tooltips="false"
            />
        </label>

        <label class="flex-1">
            <h4>Filter by Brand</h4>

            <Multiselect
                v-model="store.selectedMake"
                :options="carMakes"
                :canClear="false"
                :canDeselect="false"
                :openDirection="'top'"
                class="text-sm font-bold text-mid lg:text-xs xl:text-sm"
            />
        </label>

        <button
            type="submit"
            class="group relative rounded-full bg-brand2 px-6 py-3 text-lg font-bold text-white transition disabled:opacity-50 lg:text-base xl:text-lg"
            @click.prevent="scrollToCarsList"
        >
            Find an EV
        </button>
    </form>
</template>

<style src="@vueform/slider/themes/default.css"></style>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
    form {
        position: relative;
        padding: var(--padding);
        max-width: 100%;
        width: 100%;
        height: auto;
        display: flex;
        @apply flex-col;
        gap: 22px;

        @screen sm {
        }

        @screen lg {
            @apply flex-row items-center justify-between;
            position: sticky;
            top: calc(100vh - 7.5rem - 50px);
            height: 6rem;
            gap: 1rem;
            --ms-font-size: 0.75rem;

            z-index: 50;
            border-radius: 5.65rem;
            max-width: min(calc(100vw - (var(--padding) * 2)), 1810px);
            padding: 15px 30px;
            margin-top: calc(-7.5rem - 50px);
            margin-inline: auto;
            box-shadow: 0px 0px 20px 0px rgba(37, 78, 255, 0.17);

            .form-title {
                @apply flex-1;
            }
        }

        @screen xl {
            --ms-font-size: 1rem;
            height: 7.5rem;
            padding: var(--padding) 3.5rem;
            gap: 2.5rem;
        }
    }

    form label {
        h4 {
            @apply text-base font-bold lg:text-sm xl:text-base;
        }
    }

    .form .slider-connects {
        overflow: visible;
        .slider-connect {
            top: -3px;
            height: 7px;
        }
    }

    .multiselect {
        border-width: 0 0 1px 0;
        min-height: 40px;

        &.is-active {
            box-shadow: none;
            border-width: 0 0 1px 0;
        }
    }

    small {
        font-size: 0.6em;

        &::before {
            content: '(';
        }

        &::after {
            content: ')';
        }
    }
</style>
