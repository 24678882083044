<script setup>
    const props = defineProps({
        title: String,
        currentStep: Number,
    })

    const store = useStore()

    const stepLinks = {
        1: '/',
        2: '/quote',
        3: '/enquire',
    }

    const isStepEnabled = (step) => {
        if (step == 2) {
            return store.selectedCar != null
        }

        if (step == 3) {
            return store.selectedCar != null && store.postcode != null
        }

        return true
    }

    const goToStep = (step) => {
        if (!isStepEnabled(step)) {
            return false
        }

        navigateTo(stepLinks[step])
    }
</script>

<template>
    <header
        class="relative mb-8 mt-8 flex flex-col justify-between gap-8 pt-4 sm:mb-16 sm:flex-row sm:items-center"
    >
        <h2 class="flex-1" v-html="title.replace(/<\/?p>/g, '')"></h2>

        <nav
            class="-order-1 flex overflow-hidden whitespace-nowrap rounded-full border font-arial font-bold text-brand2 sm:order-1"
            :class="{ hidden: currentStep == 1 }"
        >
            <button
                v-for="(link, step) in stepLinks"
                :key="step"
                :to="link"
                class="grow py-0.5 disabled:cursor-not-allowed"
                :class="{
                    'bg-brand2': step <= currentStep,
                    'text-white': step <= currentStep,
                    'pl-3': step != 1,
                    'pl-4': step == 1,
                    'pr-3': step != 3,
                    'pr-4': step == 3,
                }"
                @click.prevent="goToStep(step)"
                :disabled="!isStepEnabled(step)"
            >
                Step {{ step }}
            </button>
        </nav>

        <Transition>
            <CloseButton
                class="absolute -top-8 right-0 font-bold text-brand1"
                v-if="currentStep != 1"
                href="/"
            >
                Close
            </CloseButton>
        </Transition>
    </header>
</template>

<style scoped>
    h2 {
        view-transition-name: step-title;
    }
</style>
