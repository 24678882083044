<script setup>
    const props = defineProps({
        href: {
            type: String,
            default: null,
        },
    })

    const el = ref(null)

    if (props.href) {
        el.value = 'a'
    } else {
        el.value = 'button'
    }

    const navigate = () => {
        if (props.href) {
            navigateTo(props.href)
        }
    }
</script>

<template>
    <component
        @click.prevent="navigate"
        :is="el"
        :href="href"
        class="icon-plus absolute flex items-center gap-1 font-bold text-brand1"
    >
        <span><slot /></span>
    </component>
</template>

<style scoped>
    a:after,
    button:after {
        content: '';
        @apply block;
        @apply aspect-square;
        @apply transition;
        @apply rotate-45;
        height: 1em;
        mask: var(--icon) no-repeat center / contain;
        background-color: currentColor;
    }
</style>
