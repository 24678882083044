<script setup>
    defineProps({
        car: Object,
    })

    const store = useStore()

    const getQuote = (car) => {
        store.selectCar(car)
        navigateTo('/quote')
    }
</script>

<template>
    <div
        class="relative flex w-full max-w-[500px] flex-col gap-y-2"
        @click="getQuote(car)"
        :class="{
            'is-selected': store.selectedCar?.id == car.id,
        }"
    >
        <figure class="relative aspect-[1391/718] h-full w-full md:aspect-[646/313]">
            <img
                :src="car.feature_image?.permalink.replace('uploads/', 'optimised/') ?? ''"
                class="inset-0 h-full w-full object-cover"
            />

            <div
                class="absolute inset-0 flex items-center justify-center px-[15px] py-4 font-bold text-white transition hover:opacity-100 lg:px-4 lg:opacity-0 xl:px-6"
            >
                <button
                    class="icon icon-arrow-top-right pointer-events-none invisible flex w-fit items-center justify-center rounded-full bg-brand1 px-6 py-3 font-bold text-white after:ml-[.6em] after:block after:aspect-square after:h-3 lg:visible"
                >
                    Get a quote
                </button>
            </div>
        </figure>

        <div class="relative">
            <h3 class="text-lg font-bold">{{ car.title }}</h3>
            <div class="text-mid">
                {{ prettyPrice(car.price.from) }}
                <template v-if="car.price.to">- {{ prettyPrice(car.price.to) }}</template>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .is-selected {
        img {
            view-transition-name: car-image;
        }
    }

    figure:hover,
    .3. + is-selected figure {
        > div {
            background: rgba(0, 0, 0, 0.3);
        }
    }
</style>
